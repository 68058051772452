import React, { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { useCart } from "react-use-cart"

import InputWithHiddenLabel from "../UI/InputWithHiddenLabel"
import ListboxSelect from "../UI/ListboxSelect"
import RadioGroupSelect from "../UI/RadioGroupSelect"
import Textarea from "../UI/Textarea"
import InputWrapper from "../UI/InputWrapper"

import Dimensions from "./Dimensions"

import { radioA, radioLED, coversData, colorsData } from "./data"

import Header from "./Header"
import Wrapper from "./Wrapper"

import AddButton from "../UI/AddButton"

import Alert from "../UI/Alert"
import Notification from "../UI/Notification"

function RForm({ data }) {
  const [id, setId] = useState(uuidv4())

  const [dNum, setDNum] = useState("")

  const [a, setA] = useState("")
  const [b, setB] = useState("")
  const [h, setH] = useState("")
  const [n, setN] = useState("")

  const [cover, setCover] = useState(coversData[0])
  const [color, setColor] = useState(colorsData[0])
  const [colorID, setColorID] = useState("")

  const [comment, setComment] = useState("")
  const [selected, setSelected] = useState("")
  const [selected2, setSelected2] = useState("")

  const { addItem } = useCart()

  const [isAlertVisible, setAlertVisible] = useState(false)
  const [isNotificationVisible, setNotificationVisible] = useState(false)

  const emptyForm = () => {
    setId(uuidv4())

    setDNum("")
    setA("")
    setB("")
    setH("")
    setN("")

    setCover(coversData[0])
    setColor(colorsData[0])
    setColorID("")

    setComment("")
    setSelected("")
    setSelected2("")
  }

  const handleClick = () => {
    if (
      dNum === "" ||
      a === "" ||
      b === "" ||
      h === "" ||
      n === "" ||
      selected === "" ||
      selected2 === ""
    ) {
      setAlertVisible(true)
      setNotificationVisible(false)
    } else {
      window.scrollTo(0, 0)

      setAlertVisible(false)
      setNotificationVisible(true)

      addItem(
        {
          id,
          name: "Стеновые и потолочные рейки",
          dNum,
          a,
          b,
          h,
          cover,
          color,
          colorID,
          selected,
          selected2,
          comment,
          price: 1,
        },
        n
      )

      emptyForm()
    }
  }

  return (
    <div className="space-y-2">
      <Wrapper>
        <Header title="Стеновые и потолочные рейки">
          <InputWithHiddenLabel
            value={dNum}
            setValue={setDNum}
            label="№ договора"
            type="text"
            name="numD"
            id="numD"
            placeholder="№ договора"
          />
        </Header>
        <Dimensions
          a={a}
          b={b}
          h={h}
          n={n}
          setA={setA}
          setB={setB}
          setH={setH}
          setN={setN}
        />

        <div className="grid grid-cols-1 justify-start space-x-0 border-b border-gray-300 pb-4 sm:flex sm:flex-wrap md:space-x-8">
          <InputWrapper label="Покрытие" className="justify-between">
            <ListboxSelect
              data={coversData}
              value={cover}
              onChange={setCover}
            />
          </InputWrapper>

          <InputWrapper label="Цвет" className="justify-between">
            <ListboxSelect
              data={colorsData}
              value={color}
              onChange={setColor}
            />
          </InputWrapper>

          <div className="pt-1">
            <InputWithHiddenLabel
              value={colorID}
              setValue={setColorID}
              label="№ цвета"
              type="text"
              name="numС"
              id="numС"
              placeholder="№ цвета"
            />
          </div>
        </div>

        <div className="">
          <h3 className="pt-2 text-base sm:text-lg">
            Крепление (*a - лицевая сторона)
          </h3>
          <div className="grid grid-cols-3">
            <div className="text-sm sm:text-base">Клеевое на стену</div>
            <div className="text-sm sm:text-base">
              Скрытое на стену и потолок
            </div>
            <div className="text-sm sm:text-base">Подвесное на потолок</div>
          </div>
          <RadioGroupSelect
            selected={selected}
            setSelected={setSelected}
            data={data}
            items={
              a > b
                ? [...radioA].slice(0, 3)
                : a < b
                ? [...radioA].slice(3, 6)
                : [...radioA].slice(6, 9)
            }
            a={a}
            b={b}
          />
        </div>
        <div className="border-t border-gray-300">
          <h3 className="pt-2 text-base sm:text-lg">LED подсветка</h3>
          <RadioGroupSelect
            selected={selected2}
            setSelected={setSelected2}
            data={data}
            items={
              a > b
                ? [...radioLED].slice(0, 4)
                : a < b
                ? [...radioLED].slice(4, 8)
                : [...radioLED].slice(8, 12)
            }
            columns={4}
          />
        </div>
        <div className="pb-4">
          <Textarea value={comment} setValue={setComment} />
        </div>

        <ul className="py-2 font-mono text-xs ">
          <li>№ договора: {dNum}</li>
          <li>
            Сечение: {a} мм x {b} мм, Длина: {h} мм, Количество: {n} шт.,
            Покрытие: {cover.name}
          </li>
          <li>
            Цвет: {color.name}, № цвета: {colorID}
          </li>

          <li>Крепление: {selected?.label}</li>
          <li>LED подсветка: {selected2?.label}</li>
          <li>Комментарии: {comment}</li>
        </ul>
      </Wrapper>

      <Alert
        isVisible={isAlertVisible}
        dNum={dNum}
        a={a}
        b={b}
        h={h}
        n={n}
        selected={selected}
        selected2={selected2}
      />
      <AddButton onClick={handleClick} />
      <Notification
        isVisible={isNotificationVisible}
        setVisible={setNotificationVisible}
      />
    </div>
  )
}

export default RForm
